import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { Item } from '@sitecore-jss/sitecore-jss/layout';
import { Subscription } from 'rxjs';
import { SitecoreComponent } from 'components/shared/sitecore';
import { Util } from 'core/utils/util';
import { CimaToken, Cloudinary } from 'core/services';
import { XmStore, XmStoreUtil } from 'services/store';
import { StoreAction } from 'core/store-actions';
import { AccountInfo } from 'core/store/account/model/account';

@Component({
    selector: 'xm-primary-nav',
    styleUrls: [ './primary.scss' ],
    templateUrl: './primary.html'
})
export class MarketingNavPrimaryComponent extends SitecoreComponent implements OnDestroy, OnInit {
    @Input() public rendering: ComponentRendering;

    public checkHamburgerNavExpanded: boolean = false;
    public editorActive: boolean = false;
    public abTestExperience: string;
    public dropdownIcon: MediaImageOptions;
    public dropdownRightIcon: MediaImageOptions;
    public displayName: string;

    public pageData: NavPageData;
    public myAccountNavItems: Item[];
    public isLoggedIn: boolean;

    private subscriptions: Subscription[] = [];
    private cimaToken: CimaToken;
    private store: XmStore;

    constructor(injector: Injector, store: XmStore, cimaToken: CimaToken) {
        super(injector);

        Object.assign(this, { store, cimaToken });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<NavPageData>(this.rendering.fields);
        this.myAccountNavItems = <Item[]> this.rendering.fields.myAccountUnauthenticated;

        this.subscriptions.push(this.cimaToken.loginChange.subscribe(() => {
            if (this.cimaToken.isLoggedIn) {
                this.subscriptions.push(XmStoreUtil.subscribe(this.store.find(StoreAction.GET_ACCOUNT, AccountInfo), (account: AccountInfo) => {
                    this.displayName = account.displayName;
                }));

                this.myAccountNavItems = <Item[]> this.rendering.fields.myAccountAuthenticated;
            }

            this.isLoggedIn = this.cimaToken.isLoggedIn;
        }));

        if (this.isExperienceEditorActive()) {
            this.editorActive = true;
        }

        if (this.pageData.caretIcon) {
            this.dropdownIcon = { small : Cloudinary.generateMediaFromCms(this.pageData.caretIcon) };
            this.dropdownRightIcon = { small : Cloudinary.generateMediaFromCms(this.pageData.caretRightBlueIcon) };
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public closeHamburgerNavMenu(): void {
        this.checkHamburgerNavExpanded = !this.checkHamburgerNavExpanded;
    }
}
