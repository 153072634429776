import { Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'xm-faq',
    styleUrls: [ './faq.scss' ],
    templateUrl: './faq.html'
})
export class MarketingFAQComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public pageData: QuestionsPageData;
    public hasActions: boolean = false;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<QuestionsPageData>(this.rendering.fields);
        this.hasActions = this.checkForActions();
    }

    private checkForActions(): boolean {
        return Boolean(this.hasFieldOrEditable(this.rendering.fields.ctaOne) ||
                this.hasFieldOrEditable(this.rendering.fields.linkDecorationOne)
            );
    }
  }
