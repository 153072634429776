import { AfterViewInit, Component, HostListener, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { StateService } from '@uirouter/angular';

import { SitecoreComponent } from 'components/shared/sitecore';
import { BreakpointWidth } from 'core/constants';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'Unl-Plan-Module',
    styleUrls: [ './unl-plan.scss' ],
    templateUrl: './unl-plan.html'
})
export class MarketingUnlPlanComponent extends SitecoreComponent implements OnInit, AfterViewInit {
    @Input() public rendering: ComponentRendering;

    public pageData: UnlPlanPageData;
    public paginatedTableData: PricingTableBody[] = [];
    public tableHeaders: PricingTableHeadersData[] = [];
    public paginationOptions: {
        key: string;
        label: string;
        value: number[];
        selected?: boolean;
    }[] = [];
    public dataIndexes: number[] = [ 1, 2, 3 ];
    public currentRange: number[] = [];
    public currentFilterOption: string = '';

    public isMobileView: boolean = false;
    public isTabletView: boolean = false;

    public filterOptions: {
        key: string;
        label: string;
        value: string;
        selected?: boolean;
    }[] = [];
    private pageSize: number = 5;
    private state: StateService;

    constructor(injector: Injector, state: StateService) {
        super(injector);

        Object.assign(this, { state });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<UnlPlanPageData>(this.rendering.fields);
        this.isMobileView = window.innerWidth < BreakpointWidth.MEDIUM;
        this.isTabletView = window.innerWidth <= BreakpointWidth.MEDIUM;

        this.createPaginatedData();
    }

    public ngAfterViewInit(): void {
        if (document.getElementById(this.state.params.data_option)) {
            document.getElementById(this.state.params.data_option).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }

    @HostListener('window:resize', [ '$event' ])
    public onResize(): void {
        this.isMobileView = window.innerWidth < BreakpointWidth.MEDIUM;
        this.isTabletView = window.innerWidth <= BreakpointWidth.MEDIUM;
        this.getPaginatedDataForRange(this.currentRange, this.currentFilterOption);
    }

    public getPaginatedDataForRange(range: number[], filterOption?: string): void {
        const formattedRange = range;
        this.currentRange = range;
        this.currentFilterOption = filterOption || this.currentFilterOption;
        if (this.isMobileView && this.currentFilterOption) {
            let filterIndex = 1;
            this.filterOptions.forEach((fO, index) => {
                if (fO.key === this.currentFilterOption) {
                    filterIndex = index + 1;
                }
            });

            this.dataIndexes = [ filterIndex ];
            this.tableHeaders = [{ tableheader: this.pageData.table.mobileHeaderone }, { tableheader: this.pageData.table.mobileHeadertwo }];

        } else {
            this.dataIndexes = [ 1, 2, 3 ];
            this.tableHeaders = this.pageData.table.headers;
        }

        this.paginatedTableData = this.pageData.table.body.slice(Number(formattedRange[0]) - 1, Number(formattedRange[1]));
    }

    public getFilteredTableData(filterOption: string): void {
        this.getPaginatedDataForRange(this.currentRange, filterOption);
    }

    public getPriceData(key: string, position: number, prices: PricingTableBody): string {
        return prices[`${key}${position}`] || '';
    }

    public generateImageFromCms(image: CmsMediaOptionsImage): MediaImageOptions {
        return Cloudinary.generateMediaOptionsFromCms(image);
    }

    private createPaginatedData = () => {
        const totalDataSize: number = this.pageData.table.body.length;

        this.filterOptions = this.pageData.table.headers.map((tHeader: PricingTableHeadersData, index: number) => {
            return {
                key: tHeader.tableheader,
                label: tHeader.tableheader,
                value: tHeader.tableheader,
                selected: index === 1
            };
        });

        if (this.filterOptions.length > 0) {
            this.filterOptions.shift();
        }

        let iterator: number = 0;
        while (iterator < totalDataSize) {
            let nextIteratorVal = iterator + 5;
            if (nextIteratorVal >= totalDataSize) {
                nextIteratorVal = totalDataSize;
            }

            const pageKey = `${iterator + 1}-${nextIteratorVal}`;
            this.paginationOptions.push({
                key: pageKey,
                label: pageKey,
                selected: this.paginationOptions.length === 0,
                value: [ iterator + 1, nextIteratorVal ]
            });
            iterator += this.pageSize;
        }

        if (this.paginationOptions.length > 0) {
            this.currentFilterOption = this.filterOptions[0].key;
            this.currentRange = this.paginationOptions[0].value;
            this.getPaginatedDataForRange(this.currentRange, this.currentFilterOption);
        }
    }
}
