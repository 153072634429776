import { Component, Injector, Input } from '@angular/core';
import { ComponentRendering, Field } from '@sitecore-jss/sitecore-jss-angular';
import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'marketing-text-only',
    styleUrls: [ './text-only.scss' ],
    templateUrl: './text-only.html'
})
export class MarketingTextOnlyComponent extends SitecoreComponent {
    private static HEADER1: string = 'header_one';
    private static HEADER2: string = 'header_two';
    private static HEADER3: string = 'header_three';
    private static HEADER4: string = 'header_four';
    @Input() public rendering: ComponentRendering;
    public pageData: TextOnlyPageData;
    public textAlignment: string;
    public cmsHeader: CmsHeaderStyle = {};
    public hasActions: boolean;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields(this.rendering.fields);
        this.textAlignment = this.pageData.align || 'align-center';
        switch (this.pageData.headerStyle) {
            case MarketingTextOnlyComponent.HEADER1:
                this.cmsHeader.header_one = true;
                break;
            case MarketingTextOnlyComponent.HEADER2:
                this.cmsHeader.header_two = true;
                break;
            case MarketingTextOnlyComponent.HEADER3:
                this.cmsHeader.header_three = true;
                break;
            case MarketingTextOnlyComponent.HEADER4:
                this.cmsHeader.header_four = true;
                break;
            default:
                this.cmsHeader.default = true;
                break;
        }

        this.hasActions = this.checkForActions();
        this.updateCopyrightYear();
    }

    private checkForActions(): boolean {
        return Boolean(this.hasFieldOrEditable(this.rendering.fields.buttonOne) ||
                this.hasFieldOrEditable(this.rendering.fields.buttonTwo) ||
                this.hasFieldOrEditable(this.rendering.fields.linkDecorationOne) ||
                this.hasFieldOrEditable(this.rendering.fields.linkDecorationTwo) ||
                this.rendering.fields.popover);
    }

    private updateCopyrightYear(): void {
        const currentYear: number = new Date().getFullYear();
        const description = this.pageData.disclaimer;
        (<Field> this.rendering.fields.disclaimer).value = description ? description.toString().replace('YEAR', currentYear.toString()) : '';
    }
}
