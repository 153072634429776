import { Injectable } from '@angular/core';
import { ComponentRendering, LayoutServiceData, RouteData } from '@sitecore-jss/sitecore-jss-angular';
import { PlaceholdersData } from '@sitecore-jss/sitecore-jss/layout';

@Injectable({
    providedIn: 'root'
})
export class CmsService {
    private activePage: LayoutServiceData;
    private targetData: LayoutServiceData;
    private abTestData: AbTestUid[] = [];

    public get routeData(): RouteData {
        return this.activePage.sitecore.route;
    }

    public get inEditMode(): boolean {
        return this.activePage && this.activePage.sitecore.context.pageEditing;
    }

    public setActivePageData(data: LayoutServiceData): void {
        this.activePage = data;
    }

    public setTargetData(data: LayoutServiceData): void {
        this.targetData = data;
    }

    public setTestData(data: AbTestUid[]): void {
        this.abTestData = data;
    }

    public getReplacedTargetData(): RouteData {
        if (Array.isArray(this.abTestData)) {
            this.abTestData.forEach((change: AbTestUid) => {

                if (change && change.experience && change.baseline) {
                    const targetData: ComponentRendering = this.getTargetData(change.experience);
                    if (targetData) {
                        return this.fillRecursively(this.routeData.placeholders, change.baseline, targetData);
                    }
                }
            });
        }

        return this.routeData;
    }

    private getTargetData(uid: string): ComponentRendering {
        return this.targetData && this.targetData.sitecore.route.placeholders.siteBody.find((data: ComponentRendering) => data.uid === uid) as ComponentRendering;
    }

    private fillRecursively(data: PlaceholdersData, uid: string, componentToReplace: ComponentRendering): PlaceholdersData {
        Object.values(data).forEach((component: ComponentRendering[]) => {
            if (Array.isArray(component)) {
                const index: number = component.findIndex((field: ComponentRendering) => field.uid === uid);

                if (index > -1) {
                    component[index] = componentToReplace;

                    return data;
                }

                component.forEach((field: ComponentRendering) => {
                    if (field.placeholders) {
                        return this.fillRecursively(field.placeholders, uid, componentToReplace);
                    }
                });
            }
        });

        return data;
    }
}
