import { Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Subscription } from 'rxjs';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { Item } from '@sitecore-jss/sitecore-jss/layout';

import { WindowReference } from 'core/services';
import { Util } from 'core/utils/util';
import { SitecoreComponent } from 'components/shared/sitecore';
import { NavItemTypes } from 'core/constants';
import { Field } from '@sitecore-jss/sitecore-jss/layout';

@Component({
    selector: 'xm-nav-hamburger',
    styleUrls: [ './hamburger.scss' ],
    templateUrl: './hamburger.html'
})
export class MarketingNavHamburgerComponent extends SitecoreComponent implements OnInit, OnChanges, OnDestroy {
    public static DELAY_POSITION: number = 300;

    @Input() public rendering: ComponentRendering;
    @Input() public displayName: string;
    @Input() public hideLearnBuy: boolean;
    @Input() public isLoggedIn: boolean;
    // DO NOT DELETE this input variable (triggerOnChanges) check primary.html for usage
    @Input() public triggerOnChanges: boolean;

    public isMobileNavOpen: boolean = false;
    public editorActive: boolean = false;

    public loginInfo: NavMenuItem;
    public mobileMenuItems: NavMenuItem[] = [];
    public hasDisplayName: boolean;
    public pageData: NavPageData;

    public myAccountRendering: Item;
    public showQuickPay: boolean;
    public logoutRendering: Item;
    public quickPayRendering: Item;
    public loginRendering: Item;

    private windowRef: WindowReference;
    private htmlBody: HTMLElement;
    private subscriptions: Subscription[] = [];
    private state: StateService;

    constructor(injector: Injector, windowRef: WindowReference, state: StateService) {
        super(injector);

        Object.assign(this, { windowRef, state });
    }

    public ngOnInit(): void {
        if (this.isExperienceEditorActive()) {
            this.editorActive = true;
        }

        this.pageData = this.flattenFields<NavPageData>(this.rendering.fields);

        if (this.rendering.fields && this.rendering.fields.myAccount) {
            this.loginInfo = this.flattenFields<NavMenuItem>((<Item> this.rendering.fields.myAccount).fields);
        }

        if (WindowReference.isWindowAvailable) {
            this.htmlBody = window.document.getElementsByTagName('body')[0];
            this.subscriptions.push(this.windowRef.breakpoint.subscribe((breakpoint: Breakpoint) => {
                if (this.isMobileNavOpen) {
                    if (!breakpoint.isSmall && !breakpoint.isMedium) {
                        this.setMobileViewStyles(false);
                    } else {
                        this.setMobileViewStyles(true);
                    }
                }
            }));
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.isMobileNavOpen) {
            this.toggleMobileNav();
        }

        if (changes && changes.displayName) {
            this.hasDisplayName = Boolean(this.displayName);
        }

        if (changes && changes.isLoggedIn) {
            this.myAccountRendering = <Item> (changes.isLoggedIn.currentValue ? this.rendering.fields.myAccountAuthenticated : this.rendering.fields.myAccountUnauthenticated);

            const myAccountNavChildren: Item[] = this.myAccountRendering ? (<Item[]> this.myAccountRendering.fields.children || []) : [];
            this.logoutRendering = myAccountNavChildren.find((child: Item) => child.fields.type && (<Field<string>> (child.fields.type)).value.toUpperCase() === NavItemTypes.LOGOUT);
            this.quickPayRendering = myAccountNavChildren.find((child: Item) => child.fields.type && (<Field<string>> (child.fields.type)).value.toUpperCase() === NavItemTypes.QUICKPAY);
            this.loginRendering = myAccountNavChildren.find((child: Item) => child.fields.type && (<Field<string>> (child.fields.type)).value.toUpperCase() === NavItemTypes.LOGIN);
        }
    }

    public logout(): void {
        this.toggleMobileNav();
        this.state.go('logout');
    }

    public toggleMobileNav(): void {
        this.isMobileNavOpen = !this.isMobileNavOpen;
        this.setMobileViewStyles(this.isMobileNavOpen);
    }

    private setMobileViewStyles(isMobileNav: boolean = false): void {
        if (isMobileNav) {
            this.htmlBody.style['overflow-y'] = 'hidden';
            window.setTimeout(() => {
                this.htmlBody.style.position = 'fixed';
            }, MarketingNavHamburgerComponent.DELAY_POSITION);
        } else {
            this.htmlBody.style.removeProperty('overflow-y');
            this.htmlBody.style.removeProperty('position');
        }
    }
}
