import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SitecoreComponent } from 'components/shared/sitecore';
import { ComponentFields } from '@sitecore-jss/sitecore-jss-angular';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'promo-modal',
    templateUrl: 'promo.html',
    styleUrls: [ 'promo.scss' ]
})
export class PromoModal extends SitecoreComponent implements OnInit {
    public data: ComponentFields;
    public stepOneImage: MediaImageOptions;
    public stepTwoImage: MediaImageOptions;
    public stepThreeImage: MediaImageOptions;

    private dialogRef: MatDialogRef<PromoModal>;

    constructor(injector: Injector, dialogRef: MatDialogRef<PromoModal>, @Inject(MAT_DIALOG_DATA) data: ComponentFields) {
        super(injector);
        dialogRef.disableClose = false;
        Object.assign(this, { data, dialogRef });
    }

    public ngOnInit(): void {
        const flattenedData: PromoBannerModalData = this.flattenFields<PromoBannerModalData>(this.data);
        this.stepOneImage = flattenedData.stepOneImage && Cloudinary.generateMediaOptionsFromCms(flattenedData.stepOneImage);
        this.stepTwoImage = flattenedData.stepTwoImage && Cloudinary.generateMediaOptionsFromCms(flattenedData.stepTwoImage);
        this.stepThreeImage = flattenedData.stepThreeImage && Cloudinary.generateMediaOptionsFromCms(flattenedData.stepThreeImage);
    }

    public close(action: string): void {
        const response: ModalResponse = {
            close: action === 'close',
            primary: action === 'primary',
            secondary: action === 'secondary'
        };

        this.dialogRef.close(response);
    }
}
