import { Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { SitecoreComponent } from 'components/shared/sitecore';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { ProductCard } from 'core/store/catalog/model/card';
import { XmStore, XmStoreUtil } from 'services/store';
import { Catalog } from 'core/store/catalog/model/catalog';
import { StoreAction } from 'core/store-actions';
import { Util } from 'core/utils/util';
import { Cloudinary } from 'services/cloudinary';
import { LocalStorage } from 'core/services';
import { StorageToken } from 'core/constants';

@Component({
    selector: 'xm-product-carousel',
    styleUrls: [ './carousel.scss' ],
    templateUrl: './carousel.html'
})
export class ProductCarouselComponent extends SitecoreComponent implements OnInit, OnDestroy {
    public static DEVICE_LIMIT: number = 12;

    @ViewChild('prevButton', { static: true, read: ElementRef }) public swiperPrevButton: ElementRef<HTMLElement>;
    @ViewChild('nextButton', { static: true, read: ElementRef }) public swiperNextButton: ElementRef<HTMLElement>;

    @Input() public rendering: ComponentRendering;
    @Input() public slug: string;

    public products: ProductCard[] = [];
    public showNewCard: boolean = false;
    public leftArrowIcon: MediaImageOptions;
    public rightArrowIcon: MediaImageOptions;
    public pageData: MarketingCarouselData;
    public config: SwiperConfigInterface;

    public byodProductCard: ProductCard;
    public tps: string;
    private subscriptions: Subscription[] = [];
    private xmStore: XmStore;
    private localStorage: LocalStorage;

    constructor(injector: Injector, xmStore: XmStore, localStorage: LocalStorage) {
        super(injector);

        Object.assign(this, { xmStore, localStorage });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<MarketingCarouselData>(this.rendering.fields);
        if (this.pageData.leftArrow) {
            this.leftArrowIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.leftArrow);
        }

        if (this.pageData.rightArrow) {
            this.rightArrowIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.rightArrow);
        }

        this.tps = this.localStorage.get(StorageToken.TPS);
        this.getCatalog();
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    private getCatalog(): void {
        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.query<Catalog>(StoreAction.GET_CATALOG, { category: 'device', limit: ProductCarouselComponent.DEVICE_LIMIT - 1, tps: this.tps }),
            (catalog: Catalog) => {
                this.products = this.pageData.brandFilter ? catalog.products.filter((item: ProductCard) => item.brand.toLowerCase() === this.pageData.brandFilter) : catalog.products;
                this.buildCarousel();
            }, () => {
                this.products = [];
            }));
    }

    private buildCarousel(): void {
        if (!this.products.length) {
            return;
        }

        this.config = {
            init: true,
            slidesPerView: 1,
            navigation: {
                prevEl: '.swiper-navigation-prev',
                nextEl: '.swiper-navigation-next'
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                960: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                1440: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                }
            }
        };
    }
}
