import { Component, HostListener, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';
import { BreakpointWidth } from 'core/constants';

@Component({
    selector: 'marketing-image-banner',
    styleUrls: [ './image-banner.scss' ],
    templateUrl: './image-banner.html'
})
export class MarketingImageBannerComponent extends SitecoreComponent implements OnInit {
    private static HEADER1: string = 'header_one';
    private static HEADER_TOP: string = 'header-top';
    private static IMAGE_POS_MOBILE_BOTTOM: string = 'mobile-bottom';
    private static IMAGE_POS_TABLET_BOTTOM: string = 'tablet-bottom';

    @Input() public rendering: ComponentRendering;
    public pageData: ImageBannerPageData;
    public textAlignment: string;
    public backgroundColorStyles: ImageBannerBackground = {};
    public breakpointImages: MediaImageOptions;
    public hasActions: boolean;
    public imageContainer: string;
    public sectionContainer: string;
    public cmsHeader: CmsHeaderStyle = {};
    public displayImageBottom: boolean;
    public linkDecorationOneIcon: MediaImageOptions;
    public linkClass: string;
    public themeClass: string;

    constructor(injector: Injector) {
        super(injector);
    }

    @HostListener('window:resize', [ '$event' ])
    public sizeChange(): void {
        this.ngOnInit();
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<ImageBannerPageData>(this.rendering.fields);

        this.textAlignment = this.pageData.textAlign || 'align-left';
        this.setBannerProp();

        if (this.pageData.foregroundImage) {
            this.breakpointImages = Cloudinary.generateMediaOptionsFromCms(this.pageData.foregroundImage);
        }

        if (this.pageData.backgroundColor) {
            this.backgroundColorStyles = {
                backgroundColor: this.pageData.backgroundColor
            };
        }

        if (this.pageData.linkDecorationOneIcon) {
            this.linkDecorationOneIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.linkDecorationOneIcon);
        }

        this.imageContainer = this.pageData.imageContainer || '';
        this.sectionContainer = this.pageData.sectionContainer || '';

        this.hasActions = this.checkForActions();

        if (this.pageData.headerStyle) {
            if (this.pageData.headerStyle === MarketingImageBannerComponent.HEADER1) {
                this.cmsHeader.header_one = true;
            } else if (this.pageData.headerStyle === MarketingImageBannerComponent.HEADER_TOP) {
                this.cmsHeader.header_top = true;
            } else {
                this.cmsHeader.default = true;
            }
        } else {
            this.cmsHeader.default = true;
        }

        if (this.pageData.imagePosition) {
            const isMobileView: boolean = window.innerWidth < BreakpointWidth.MEDIUM;
            const isTabletView: boolean = window.innerWidth <= BreakpointWidth.MEDIUM;

            if (!isMobileView && !isTabletView) {
                this.displayImageBottom = false;
            } else {
                if (isMobileView && this.pageData.imagePosition === MarketingImageBannerComponent.IMAGE_POS_MOBILE_BOTTOM) {
                    this.displayImageBottom = true;
                } else if (isTabletView && this.pageData.imagePosition === MarketingImageBannerComponent.IMAGE_POS_TABLET_BOTTOM) {
                    this.displayImageBottom = true;
                } else {
                    this.displayImageBottom = false;
                }
           }
        }

    }

    public setBannerProp(): void {
        this.themeClass = this.pageData.theme;
        if (this.themeClass === 'dark-grey') {
            this.linkClass = 'dark-grey';
        } else if (this.themeClass === 'dark') {
            this.linkClass = 'dark';
        } else if (this.themeClass === 'light') {
            this.linkClass = 'light';
        } else {
            this.linkClass = 'light-grey';
        }
    }

    private checkForActions(): boolean {
        return Boolean(this.hasFieldOrEditable(this.rendering.fields.buttonOne) ||
                this.hasFieldOrEditable(this.rendering.fields.buttonTwo) ||
                this.hasFieldOrEditable(this.rendering.fields.linkDecorationOne) ||
                this.hasFieldOrEditable(this.rendering.fields.linkDecorationTwo) ||
                this.rendering.fields.popover);
    }
}
