import { Component, Inject, Injector, Input, OnInit } from '@angular/core';
import { Field } from '@sitecore-jss/sitecore-jss-angular';
import { SitecoreComponent } from 'components/shared/sitecore';

import { UrlService } from '@uirouter/core';
import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { Decorator, LinkDecorationInfo } from 'services/decorator';

@Component({
    selector: 'xm-decorated-text',
    styleUrls: [ './decorated-text.scss' ],
    templateUrl: './decorated-text.html'
})
export class XmDecoratedText extends SitecoreComponent implements OnInit {
    @Input() public field: Field;

    @Input() public inputString: string;

    public decorationInfoArray: LinkDecorationInfo[];

    private decorator: Decorator;

    constructor(injector: Injector, decorator: Decorator, urlService: UrlService, @Inject(CONFIG_TOKEN) config: IXMOptions) {
        super(injector);
        Object.assign(this, { decorator, urlService, config });
    }

    public ngOnInit(): void {
        this.decorationInfoArray = this.decorator.parseLinkDecoration(<string> this.field.value);
    }
}
