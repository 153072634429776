<div *ngIf=" hasFieldOrEditable(rendering.fields.showBackground)" class="background"></div>
<section #parentRef class="section-container {{sectionContainer}}">
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.smallTitle)">
        <h2 class="touts-title" *scRichText="rendering.fields.smallTitle"></h2>
    </ng-container>     
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
        <h2 class="touts-header" *scRichText="rendering.fields.header"></h2>
    </ng-container>
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.secondaryHeader)">
        <h2 class="touts-secondary-header" *scRichText="rendering.fields.secondaryHeader"></h2>
    </ng-container>
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.thirdHeader)">
        <h2 class="touts-third-header" *scRichText="rendering.fields.thirdHeader"></h2>
    </ng-container>
    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.body)">
        <xm-cms-text class="touts-body" [field]="rendering.fields.body"></xm-cms-text>
    </ng-container>
    <!-- <ng-container *ngIf="hasPlaceholder(rendering, 'toutsModuleTout') && pageData.enableCarousel">
        <div [swiper]="carouselSettings" class="swiper-container">
            <div class="swiper-wrapper" sc-placeholder name="toutsModuleTout" (loaded)="placeholderLoaded()" [rendering]="rendering" [inputs]="inputs"></div>

            <div class="swiper-pagination"></div>
        </div>
    </ng-container> -->
    <swiper-container class="swiper-container"
        navigation="true" [pagination-clickable]="true" [slidesPerView]="3" *ngIf="hasPlaceholder(rendering, 'toutsModuleTout') && pageData.enableCarousel">
            <swiper-slide>
                <div class="swiper-wrapper" sc-placeholder name="toutsModuleTout" (loaded)="placeholderLoaded()" [rendering]="rendering" [inputs]="inputs"></div>
            </swiper-slide>
    </swiper-container>

    <ng-container *ngIf="hasPlaceholder(rendering, 'toutsModuleTout') && !pageData.enableCarousel">
        <div class="tout-cards" sc-placeholder name="toutsModuleTout" [rendering]="rendering" [inputs]="inputs"></div>
    </ng-container>
    <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.disclaimerText)" class="disclaimer-text" [field]="rendering.fields.disclaimerText"></xm-cms-text>
</section>
