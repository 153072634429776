import { Component, Injector, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'marketing-byod-promo',
    styleUrls: [ './byod-promo.scss' ],
    templateUrl: './byod-promo.html'
})
export class MarketingByodPromoComponent extends SitecoreComponent {
    @Input() public rendering: ComponentRendering;

    public pageData: ByodPromoPageData;
    public breakpointImages: MediaImageOptions;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<ByodPromoPageData>(this.rendering.fields);

        if (this.pageData.icon) {
            this.breakpointImages = Cloudinary.generateMediaOptionsFromCms(this.pageData.icon);
        }
    }
}
