import { AfterViewInit, Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';
import { StateService } from '@uirouter/angular';

@Component({
    selector: 'marketing-image-banner',
    styleUrls: [ './plan-details.scss' ],
    templateUrl: './plan-details.html'
})
export class MarketingPlanDetailsComponent extends SitecoreComponent implements OnInit, AfterViewInit {
    @Input() public rendering: ComponentRendering;
    @ViewChild(SwiperDirective) public swiper: SwiperDirective;

    public pageData: PlanDetailsPageData;
    public icon: MediaImageOptions;
    public leftArrowIcon: MediaImageOptions;
    public rightArrowIcon: MediaImageOptions;
    public planDetailsSideImages: MediaImageOptions;
    public sideImageClass: string;
    public isGigDataComponent: boolean;

    public carouselConfig: SwiperConfigInterface = {
        navigation: {
            prevEl: '.swiper-navigation-prev',
            nextEl: '.swiper-navigation-next'
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
            bulletElement: 'button'
        }
    };

    private state: StateService;

    constructor(injector: Injector, state: StateService) {
        super(injector);

        Object.assign(this, { state });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<PlanDetailsPageData>(this.rendering.fields);

        if (this.pageData.icon) {
            this.icon = Cloudinary.generateMediaOptionsFromCms(this.pageData.icon);
        }

        if (this.pageData.leftArrowIcon) {
            this.leftArrowIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.leftArrowIcon);
        }

        if (this.pageData.rightArrowIcon) {
            this.rightArrowIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.rightArrowIcon);
        }

        if (this.pageData.planDetailsSideImages) {
            this.planDetailsSideImages = Cloudinary.generateMediaOptionsFromCms(this.pageData.planDetailsSideImages);
        }

        if (this.pageData.header) {
            this.isGigDataComponent = this.pageData.header.toLowerCase() === 'by the gig data';
        }

        this.sideImageClass = this.pageData.sideImageClass || this.pageData.sideImageClass + 'watch';
    }

    public ngAfterViewInit(): void {
        if (document.getElementById(this.state.params.data_option)) {
            document.getElementById(this.state.params.data_option).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }

    public placeholderLoaded(): void {
        if (this.swiper) {
            this.swiper.update();
        }
    }
}
