import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterModule } from '@uirouter/angular';
import { JssModule } from '@sitecore-jss/sitecore-jss-angular';

import { XmCmsTextComponent } from './cms-text/text';
import { XmButtonComponent } from './button/button';
import { MarketingExpandableComponent } from 'components/shared/expandable/expandable';
import { SafeHtmlPipe } from './html';
import { XmMediaImageComponent } from 'components/media-image/image';
import { XmMask } from './directives/mask';
import { XmDecoratedText } from './decorated-text/decorated-text';
import { XmGenericLink } from './generic-link/generic-link';
import { XmCurrencyPipe } from './pipes';
import { XmAriaHidden } from './directives/aria-hidden';
import { SwiperA11yDirective } from './directives/carousel-a11y';
import { XmDropdownComponent } from 'components/shared/dropdown/dropdown';

@NgModule({
    declarations: [
        XmCmsTextComponent,
        XmButtonComponent,
        XmDecoratedText,
        XmGenericLink,
        XmMediaImageComponent,
        MarketingExpandableComponent,
        SafeHtmlPipe,
        XmMask,
        XmCurrencyPipe,
        XmAriaHidden,
        XmDropdownComponent,
        SwiperA11yDirective
    ],
    exports: [
        CommonModule,
        UIRouterModule,
        JssModule,
        XmCmsTextComponent,
        XmButtonComponent,
        XmDecoratedText,
        XmGenericLink,
        MarketingExpandableComponent,
        XmMediaImageComponent,
        XmMask,
        XmCurrencyPipe,
        XmAriaHidden,
        XmDropdownComponent,
        SwiperA11yDirective
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
        XmCurrencyPipe
    ],
    imports: [
        CommonModule,
        UIRouterModule,
        JssModule
    ]
})
export class SharedModule {}
