import { Component, EventEmitter, Injector, Input, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { StateService } from '@uirouter/core';
import { Item } from '@sitecore-jss/sitecore-jss/layout';
import { Subscription } from 'rxjs';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Util } from 'core/utils/util';
import { Cloudinary, GlobalNav, WindowReference } from 'core/services';
import { MouseEventType, NavItemTypes } from 'core/constants';

@Component({
    selector: 'xm-nav-menu-items',
    styleUrls: [ './menu-item.scss' ],
    templateUrl: './menu-item.html'
})
export class MarketingNavMenuItemComponent extends SitecoreComponent implements OnDestroy {
    @Input() public rendering: Item;
    @Input() public caretIcon: MediaImageOptions;
    @Input() public caretRightIcon: MediaImageOptions;
    @Input() public inheritParentWidth: boolean;
    @Input() public displayName: string;
    @Input() public showChildren: boolean;
    @Input() public expandMessge: string;
    @Output() public toggleMobileNav: EventEmitter<boolean> = new EventEmitter<boolean>();

    public pageData: NavMenuItem;
    public showChildItems: boolean;
    public showSubChildItems: boolean;
    public isChat: boolean;
    public isCart: boolean;
    public isSeparator: boolean;
    public displayIcon: MediaImageOptions;
    public isStateActive: boolean;
    public hasGenericLink: boolean;
    public changeMenuColor: boolean;
    public changeCaretIconColor: boolean = false;
    private subscriptions: Subscription[] = [];
    private state: StateService;
    private globalNav: GlobalNav;

    constructor(injector: Injector, globalNav: GlobalNav, state: StateService) {
        super(injector);

        Object.assign(this, { globalNav, state });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.rendering) {
            this.buildContent();
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public logout(): void {
        this.closeMobileMenu();
        this.state.go('logout');
    }

    public closeMobileMenu(): void {
        this.toggleMobileNav.emit();
        this.showChildItems = false;
    }

    public expandChildItems(): void {
        this.showChildItems = !this.showChildItems;
    }

    public expandSubChildItems(): void {
        this.showSubChildItems = !this.showSubChildItems;
        this.changeCaretIconColor = !this.changeCaretIconColor;
    }

    public mouseHoverEvent(event: Event): void {
        this.showChildItems = event.type === MouseEventType.MOUSE_ENTER;
        this.changeMenuColor = !this.changeMenuColor;
    }

    public mouseHoverSubEvent(event: Event, displaySubMenu: boolean): void {
        if (displaySubMenu) {
            this.showSubChildItems = event.type === MouseEventType.MOUSE_ENTER;
        }
    }

    public changeBackColor(): void {
        this.changeMenuColor = true;
    }

    private buildContent(): void {
        this.pageData = this.flattenFields<NavMenuItem>(this.rendering.fields);
        this.hasGenericLink = Boolean(this.pageData.genericLink && this.pageData.genericLink.url);
        this.isChat = this.pageData.type && this.pageData.type.toUpperCase() === NavItemTypes.CHAT;
        this.isCart = this.pageData.type && this.pageData.type.toUpperCase() === NavItemTypes.CART;
        this.isSeparator = this.pageData.type && this.pageData.type.toUpperCase() === NavItemTypes.SEPARATOR;
        if (this.pageData.children && this.pageData.children.length) {
            this.pageData.children.forEach((child: NavMenuItem) => {
                child.isLogout = Boolean(child.type && child.type.toUpperCase() === NavItemTypes.LOGOUT);
                child.isLogin = Boolean(child.type && child.type.toUpperCase() === NavItemTypes.LOGIN);
                child.isQuickPay = Boolean(child.type && child.type.toUpperCase() === NavItemTypes.QUICKPAY);

                return child;
            });
        }

        this.displayIcon = this.pageData.icon && Cloudinary.generateMediaOptionsFromCms(this.pageData.icon);
        this.isStateActive = Boolean(WindowReference.isWindowAvailable && this.globalNav.stateActive(this.pageData));
    }
}
