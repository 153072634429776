<section class="{{pageData.sectionContainer}}">
    <div class="wrapper">
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.title)">
            <h2 class="title" *scRichText="rendering.fields.title"></h2>
        </ng-container>
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.subtitle)">
            <div class="subtitle" *scRichText="rendering.fields.subtitle"></div>
        </ng-container>
        <div class="plan-details" *ngIf="icon || hasFieldOrEditable(rendering.fields.header) || pageData.isUnlimitedTableComponent" [ngClass]="{ 'unlTable': pageData.isUnlimitedTableComponent }">
            <div class="head-container" *ngIf="icon || hasFieldOrEditable(rendering.fields.header)">
                <xm-media-image class="icon text-size-icons" *ngIf="icon" [imageOptions]="icon"></xm-media-image>
                <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
                    <h3 class="header text-center" [class.text-center-mobile]="!icon" *scRichText="rendering.fields.header"></h3>
                </ng-container>
            </div>
            <div>
                <div>
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.planSizeLabel)">
                        <div class="label" *scRichText="rendering.fields.planSizeLabel"></div>
                    </ng-container>
                    <ng-container *ngIf="hasFieldOrEditable(rendering.fields.planPriceLabel)">
                        <div class="label" *scRichText="rendering.fields.planPriceLabel"></div>
                    </ng-container>
                    <div class="single-plan">
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.singlePlanSize)">
                            <div class="plan" *scRichText="rendering.fields.singlePlanSize"></div>
                        </ng-container>
                        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.singlePlanPrice)">
                            <div class="plan-description" *scRichText="rendering.fields.singlePlanPrice"></div>
                        </ng-container>
                    </div>                  
                    <table class="plan-carousel"
                        *ngIf="hasFieldOrEditable(rendering.fields.carouselPlanName) || hasFieldOrEditable(rendering.fields.carouselPlanPrice)">
                        <div class="carousel-headers">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.carouselPlanName)">
                                <div class="plan-name" *scRichText="rendering.fields.carouselPlanName"></div>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.carouselPlanPrice)">
                                <div class="plan-price plan-price-heading unlimited-subtitle" *scRichText="rendering.fields.carouselPlanPrice"></div>
                            </ng-container>
                        </div>
                        <tr class="table-head" *ngIf="hasFieldOrEditable(rendering.fields.carouselFirstColumnHeader)">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.carouselFirstColumnHeader)">
                                <th class="first-column" scope="col" *scRichText="rendering.fields.carouselFirstColumnHeader">
                                </th>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.carouselSecondColumnHeader)">
                                <th class="second-column" scope="col" *scRichText="rendering.fields.carouselSecondColumnHeader">
                                </th>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.carouselThirdColumnHeader)">
                                <th class="third-column" scope="col" *scRichText="rendering.fields.carouselThirdColumnHeader">
                                </th>
                            </ng-container>
                        </tr>
                        <div class="table-head" *ngIf="hasFieldOrEditable(rendering.fields.carouselFirstColumnHeader2)">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.carouselFirstColumnHeader2)">
                                <div class="first-column2" *scRichText="rendering.fields.carouselFirstColumnHeader2">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.carouselSecondColumnHeader2)">
                                <div class="first-column2" *scRichText="rendering.fields.carouselSecondColumnHeader2">
                                </div>
                            </ng-container>
                        </div>
                        <!-- <div class="swiper-body" *ngIf="leftArrowIcon">
                            <span class="swiper-navigation-prev carousel-Arrow-Icon-style2" *ngIf="leftArrowIcon">
                                <xm-media-image [imageOptions]="leftArrowIcon" size="height"
                                    class="arrow carousel-Arrow-Icon-style">
                                </xm-media-image>
                            </span>
                            <div class="swiper-container" [swiper]="carouselConfig" [a11yLabel]="pageData.header">
                                <div class="swiper-wrapper" sc-placeholder name="unlPricingGroups"
                                    [rendering]="rendering" (loaded)="placeholderLoaded()"></div>
                            </div>
                            <span class="swiper-navigation-next carousel-Arrow-Icon-style2" *ngIf="leftArrowIcon">
                                <xm-media-image [imageOptions]="rightArrowIcon" size="height"
                                    class="arrow carousel-Arrow-Icon-style">
                                </xm-media-image>
                            </span>
                            <div #pagination class="swiper-pagination"></div>
                        </div> -->

                        <swiper-container class="swiper-container"
                            navigation="true" [pagination-clickable]="true" [slidesPerView]="3" [a11yLabel]="pageData.header">
                                <swiper-slide>
                                    <div class="swiper-wrapper" sc-placeholder name="unlPricingGroups"
                                    [rendering]="rendering" (loaded)="placeholderLoaded()"></div>
                                </swiper-slide>
                        </swiper-container>

                    </table>
                    <ul id="btg" class="btg-container" *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeOne) || hasFieldOrEditable(rendering.fields.multiplePlanPriceOne)">
                        <li class="plan-list" *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeOne) || hasFieldOrEditable(rendering.fields.multiplePlanPriceOne)">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeOne)">
                                <div class="row plan-list-row plan-list-row-top" *scRichText="rendering.fields.multiplePlanSizeOne">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanPriceOne)">
                                <div class="row plan-list-row plan-list-row-bottom" *scRichText="rendering.fields.multiplePlanPriceOne">
                                </div>
                            </ng-container>
                        </li>
                        <li class="plan-list" *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeTwo) || hasFieldOrEditable(rendering.fields.multiplePlanPriceTwo)">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeTwo)">
                                <div class="row plan-list-row plan-list-row-top" *scRichText="rendering.fields.multiplePlanSizeTwo">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanPriceTwo)">
                                <div class="row plan-list-row plan-list-row-bottom" *scRichText="rendering.fields.multiplePlanPriceTwo">
                                </div>
                            </ng-container>
                        </li>
                        <li class="plan-list" *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeThree) || hasFieldOrEditable(rendering.fields.multiplePlanPriceThree)">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeThree)">
                                <div class="row plan-list-row plan-list-row-top"
                                    *scRichText="rendering.fields.multiplePlanSizeThree">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanPriceThree)">
                                <div class="row plan-list-row plan-list-row-bottom"
                                    *scRichText="rendering.fields.multiplePlanPriceThree">
                                </div>
                            </ng-container>
                        </li>
                        <div *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeFour)" class="image-container btg-divider"></div>
                        <li class="plan-list" *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeFour) || hasFieldOrEditable(rendering.fields.multiplePlanPriceFour)">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeFour)">
                                <div class="row plan-list-row plan-list-row-top"
                                    *scRichText="rendering.fields.multiplePlanSizeFour">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanPriceFour)">
                                <div class="row plan-list-row plan-list-row-bottom"
                                    *scRichText="rendering.fields.multiplePlanPriceFour">
                                </div>
                            </ng-container>
                        </li>
                        <li class="plan-list" *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeFive) || hasFieldOrEditable(rendering.fields.multiplePlanPriceFive)">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeFive)">
                                <div class="row plan-list-row plan-list-row-top"
                                    *scRichText="rendering.fields.multiplePlanSizeFive">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanPriceFive)">
                                <div class="row plan-list-row plan-list-row-bottom"
                                    *scRichText="rendering.fields.multiplePlanPriceFive">
                                </div>
                            </ng-container>
                        </li>
                        <li class="plan-list" *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeSix) || hasFieldOrEditable(rendering.fields.multiplePlanPriceSix)">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanSizeSix)">
                                <div class="row plan-list-row plan-list-row-top"
                                    *scRichText="rendering.fields.multiplePlanSizeSix">
                                </div>
                            </ng-container>
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.multiplePlanPriceSix)">
                                <div class="row plan-list-row plan-list-row-bottom"
                                    *scRichText="rendering.fields.multiplePlanPriceSix">
                                </div>
                            </ng-container>
                        </li>
                    </ul>
                </div>

                <div>
                    <div>
                        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.disclaimer)" class="disclaimer text-center"
                            [field]="rendering.fields.disclaimer"></xm-cms-text>
                        <xm-popover [customClass]="'price-other-info-plan'" *ngIf="rendering.fields.popover" class="popover text-center"
                            [ngClass]="{ 'popover-plan-details': isGigDataComponent }" [rendering]="rendering.fields.popover"
                            position="center">
                        </xm-popover>
                    </div>
                    <div class="wrapper-image-text wrapper-py">
                        <xm-media-image *ngIf="rendering.fields.planDetailsSideImages" [imageOptions]="planDetailsSideImages">
                        </xm-media-image>
                        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.body)" class="body cms-text-alignment"
                            [field]="rendering.fields.body"></xm-cms-text>
                        <ul *ngIf="rendering.fields.listItems" class="body cms-text-alignment list-container">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.subHeader)" class="list-header">
                                <h3 class="subHeader" *scRichText="rendering.fields.subHeader"></h3>
                            </ng-container>   
                            <li class="bullet-list" *ngFor="let listitem of rendering.fields.listItems" >
                                <xm-cms-text class="plan-details-cms-text-bullets plan-details-list list" [customClass]="'plan-details-cms-text-bullets'" [field]="listitem.fields.body"></xm-cms-text>
                            </li>   
                        </ul>
                    </div>
                </div>
                <xm-generic-link *ngIf="hasFieldOrEditable(rendering.fields.shopLink)" class="cta bottom-link text-center" [field]="rendering.fields.shopLink"></xm-generic-link>  
            </div>
        </div>
        <div class="plan-details" *ngIf="hasFieldOrEditable(rendering.fields.header2)">
            <div>
                <div class="wrapper-image-text">
                    <xm-media-image [imageOptions]="planDetailsSideImages" class="{{sideImageClass}}">
                    </xm-media-image>
                    <div class="body cms-text-alignment cms-text-alignment-margin">
                        <ng-container>
                            <h3 class="header2 selection-container-header2" [class.text-center-mobile]="!icon" *scRichText="rendering.fields.header2"></h3>
                        </ng-container>
                        <div class="single-plan">
                            <ng-container *ngIf="hasFieldOrEditable(rendering.fields.singlePlanPrice)">
                                <div class="plan-description2 selection-container-header2" *scRichText="rendering.fields.singlePlanPrice"></div>
                            </ng-container>
                        </div>
                        <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.disclaimer)" class="disclaimer"
                        [field]="rendering.fields.disclaimer"></xm-cms-text>
                    <xm-popover [customClass]="'price-other-info-plan'" *ngIf="rendering.fields.popover" class="popover selection-container-header2" [rendering]="rendering.fields.popover"
                        position="center">
                    </xm-popover>
                    
                    <xm-cms-text *ngIf="hasFieldOrEditable(rendering.fields.body)" class=""
                        [field]="rendering.fields.body"></xm-cms-text>
                    <ul *ngIf="rendering.fields.listItems" class="list-container margin-t-24">
                        <li class ="bullet-list" *ngFor="let listitem of rendering.fields.listItems">
                            <xm-cms-text class="plan-details-cms-text-bullets plan-details-list list" [customClass]="'plan-details-cms-text-bullets'" [field]="listitem.fields.body"></xm-cms-text>
                        </li>   
                    </ul>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>