import { Component, Injector, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';
import { FormUtil } from 'core/utils/form';
import { Analytics } from 'core/services';

@Component({
    selector: 'prereg',
    styleUrls: [ './prereg.scss' ],
    templateUrl: './prereg.html'
})
export class PreregComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public pageData: PreregPageData;
    public bannerImage: MediaImageOptions;
    public confirmationIcon: MediaImageOptions;
    public textAlignment: string;
    public formGroup: UntypedFormGroup;
    public emailFormControl: UntypedFormControl;
    public iframeUrl: SafeUrl;
    public formSubmitted: boolean = false;

    private sanitizer: DomSanitizer;
    private analytics: Analytics;

    constructor(injector: Injector, analytics: Analytics, sanitizer: DomSanitizer) {
        super(injector);

        Object.assign(this, { analytics, sanitizer });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<PreregPageData>(this.rendering.fields);
        this.textAlignment = this.pageData.textAlign || 'align-center';

        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.pageData.formAction);

        // Default events won't get fired on load but stores when user clicks any button next time
        // We will change/remove this later if this is not required
        this.setDigitalData(this.pageData.dataLayerDefaultOverrides, false);

        this.emailFormControl = this.pageData.emailErrors ? new UntypedFormControl('', { validators: FormUtil.validators(this.pageData.emailErrors), updateOn: 'blur' }) : new UntypedFormControl('', { updateOn: 'blur' });

        this.formGroup = new UntypedFormGroup({
            email: this.emailFormControl
        });

        if (this.pageData.bannerImage) {
            this.bannerImage = Cloudinary.generateMediaOptionsFromCms(this.pageData.bannerImage);
        }

        if (this.pageData.confirmationIcon) {
            this.confirmationIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.confirmationIcon);
        }
    }

    public submit(): void {
        FormUtil.submitForm(this.formGroup, this.postForm.bind(this));
    }

    public postForm(): void {
        if (this.emailFormControl.valid) {
            const dataForm: HTMLFormElement = <HTMLFormElement> window.document.getElementById('signUp');
            this.setDigitalData(this.pageData.dataLayerSuccessOverrides, true);
            dataForm.submit();
            setTimeout(() => {
                this.formSubmitted = true;
            }, 300);
        }
    }

    private setDigitalData(overridePageInfo: object, sendCustomLoadEvent: boolean): void {
        if (overridePageInfo) {
            const customParams: TDigitalData = {
                page: {
                    pageInfo: overridePageInfo,
                    category: overridePageInfo
                }
            };
            this.analytics.updateCustomParams(customParams, sendCustomLoadEvent);
        }
    }
}
