import { Component, Inject, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { Util } from 'core/utils/util';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';
import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';
import { Decorator } from 'services/decorator';
import { SafeUrl } from '@angular/platform-browser';

@Component({
    selector: 'xm-product-byod-card',
    styleUrls: [ './byod-card.scss' ],
    templateUrl: './byod-card.html',
    preserveWhitespaces: true
})
export class ProductByodCardComponent extends SitecoreComponent implements OnInit, OnDestroy {
    @Input() public rendering: ComponentRendering;

    public isSingleLineEllipsis: boolean;
    public isMultiLineEllipsis: boolean;
    public promoIcon: MediaImageOptions;
    public pageData: MarketingByodProductCard;
    public productLink: SafeUrl;
    public downPaymentText: string = '';
    public promoLabel: string;
    public hideStrikeThrough: boolean = false;
    public abTestExperience: boolean;
    public byodImageOptions: MediaImageOptions;
    public prepaidImageOptions: MediaImageOptions;

    private subscriptions: Subscription[] = [];
    private decorator: Decorator;

    constructor(injector: Injector, @Inject(CONFIG_TOKEN) config: IXMOptions, decorator: Decorator) {
        super(injector);

        Object.assign(this, { config, decorator });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<MarketingByodProductCard>(this.rendering.fields);

        if (this.pageData.promoImage) {
            this.prepaidImageOptions = Cloudinary.generateMediaOptionsFromCms(this.pageData.promoImage);
        }

        this.promoLabel = this.pageData.promoLabel;

        if (this.pageData.byodLink) {
            this.productLink = this.decorator.createSpaLinks(this.pageData.byodLink).absoluteUrl;
        }

        if (this.pageData.byodImage) {
            this.byodImageOptions = Cloudinary.generateMediaOptionsFromCms(this.pageData.byodImage);
        }
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }
}
