import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Item } from '@sitecore-jss/sitecore-jss/layout';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Chat, Cloudinary } from 'core/services';
import { MouseEventType } from 'core/constants';

@Component({
    selector: 'xm-nav-chat',
    styleUrls: [ './chat.scss' ],
    templateUrl: './chat.html'
})
export class MarketingNavChatComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: Item;
    @Output() public toggleMobileNav: EventEmitter<void> = new EventEmitter<void>();

    public pageData: NavMenuItem;
    public displayIcon: MediaImageOptions;
    public chatIcon: MediaImageOptions;
    public chatHoverIcon: MediaImageOptions;

    private chat: Chat;

    constructor(injector: Injector, chat: Chat) {
        super(injector);

        Object.assign(this, { chat });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<NavMenuItem>(this.rendering.fields);

        this.chatIcon = this.pageData.icon && Cloudinary.generateMediaOptionsFromCms(this.pageData.icon);
        this.chatHoverIcon = this.pageData.hoverIcon && Cloudinary.generateMediaOptionsFromCms(this.pageData.hoverIcon);

        this.displayIcon = this.chatIcon;
    }

    public openChat(): void {
        this.toggleMobileNav.emit();
        this.chat.openChat();
    }

    public mouseHoverEvent(event: Event): void {
        this.displayIcon = event.type === MouseEventType.MOUSE_ENTER && this.chatHoverIcon ? this.chatHoverIcon : this.chatIcon;
    }
}
