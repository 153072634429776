<section>
    <div class="background-section" >
        <xm-media-image class="background-image" *ngFor="let background of backgroundImages; let i = index" [ngClass]="{ 'visible-background': activeSlideIndex === i }" [imageOptions]="background.mediaImage"></xm-media-image>
        <xm-media-image *ngIf="xmLogo" class="xm-logo" [imageOptions]="xmLogo"></xm-media-image>
        <ng-container *ngIf="hasFieldOrEditable(rendering.fields.header)">
            <h2 class="header" *scRichText="rendering.fields.header"></h2>
        </ng-container>
    </div>
    <!-- <div class="carousel-container swiper-container" *ngIf="hasPlaceholder(rendering, 'planCarouselVariant')" [swiper]="carouselSettings" (indexChange)="onIndexChange($event)">
        <button *ngIf="leftArrow" class="swiper-navigation-prev">
            <xm-media-image [imageOptions]="leftArrow"></xm-media-image>
        </button>
        <div class="swiper-wrapper" sc-placeholder name="planCarouselVariant" (loaded)="placeholderLoaded()" [rendering]="rendering"></div>
        <button *ngIf="rightArrow" class="swiper-navigation-next">
            <xm-media-image [imageOptions]="rightArrow"></xm-media-image>
        </button>
        <div class="footer mobile" *ngIf="hasFieldOrEditable(rendering.fields.footer)">
            <div *scRichText="rendering.fields.footer"></div>
        </div>

        <div class="swiper-pagination"></div>

        <div class="footer desktop" *ngIf="hasFieldOrEditable(rendering.fields.footer)">
            <div *scRichText="rendering.fields.footer"></div>
        </div>
    </div> -->

    <swiper-container class="swiper-container"
        navigation="true" [pagination-clickable]="true" [slidesPerView]="3" *ngIf="hasPlaceholder(rendering, 'planCarouselVariant')" (indexChange)="onIndexChange($event)">
            <swiper-slide>
                <div class="swiper-wrapper" sc-placeholder name="planCarouselVariant" (loaded)="placeholderLoaded()" [rendering]="rendering"></div>
            </swiper-slide>
    </swiper-container>
</section>
