import { Injector } from '@angular/core';
import { ComponentFields, ComponentRendering, Field } from '@sitecore-jss/sitecore-jss-angular';
import { Item } from '@sitecore-jss/sitecore-jss/layout';
import { LinkFieldValue } from '@sitecore-jss/sitecore-jss-manifest';
import { GenericFieldValue } from '@sitecore-jss/sitecore-jss/types/layout/models';

import { CmsService } from 'services/cms';
import { SitecoreUtil } from 'core/utils/sitecore';

export class SitecoreComponent {
    public isExperienceEditorActive: () => boolean = SitecoreUtil.isExperienceEditorActive;
    public getFieldValue: <T>(renderingOrFields: ComponentRendering | ComponentFields, fieldName: string, defaultValue?: T) => T = SitecoreUtil.getFieldValue;
    public flattenFields: <T>(fields: ComponentFields) => T = SitecoreUtil.flattenFields;

    // using the _cms name so this does not conflict with any
    // component that extends from this one
    private _cms: CmsService;

    constructor(injector: Injector) {
        this._cms = injector.get(CmsService);
    }

    public hasFieldOrEditable(field: Field | Item | Item[]): boolean {
        if (!field || Array.isArray(field)) {
            return false;
        }

        let valueData: GenericFieldValue = (<Field>field).value;
        // special handling for GenericLink type        // if href has no value then it is assumed the field is blank
        if (typeof (<Field<LinkFieldValue>>(field as unknown)).value === 'object' && 'href' in (<Field<LinkFieldValue>>(field as unknown)).value) {
            valueData = (field as unknown as Field<LinkFieldValue>).value.href;
        }

        return Boolean(valueData || (<Field>field).editable);
        
    }

    public get inEditMode(): boolean {
        return this._cms && this._cms.inEditMode;
    }

    public inEditModeOr(decision: boolean): boolean {
        return this.inEditMode || decision;
    }

    public hasPlaceholder(rendering: ComponentRendering, name: string): boolean {
        return Boolean(rendering?.placeholders?.[name]);
    }

    public getFieldItem(fields: ComponentFields, fieldName: string): Item {
        return <Item> fields?.[fieldName];
    }
}
