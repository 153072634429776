import { Component, ElementRef, Injector, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ComponentFields, ComponentRendering, Field } from '@sitecore-jss/sitecore-jss-angular';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Modal } from 'core/services/modal';
import { MouseEventType } from 'core/constants';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'xm-popover',
    templateUrl: 'popover.html',
    styleUrls: [ 'popover.scss' ]
})
export class MarketingPopoverComponent extends SitecoreComponent implements OnInit {
    private static MOUSE_ENTER_DELAY_TIME: number = 2000;
    private static MOUSE_LEAVE_DELAY_TIME: number = 1000;
    @Input() public rendering: ComponentRendering;
    @Input() public parentTemplate: HTMLElement;
    @Input() public showByodPromoStyles: boolean;
    @Input() public customClass: string = '';
    @Input() public theme: string = '';

    @ViewChild('innerPopover', { static: true }) public childTemplate: ElementRef;

    public pageData: PopoverPageData;
    public showOverlay: boolean = false;
    public linkClass: string;
    public headerClass: string;
    public bodyContentClass: string;
    public modalData: ComponentFields;

    private modal: Modal;
    private interval: NodeJS.Timer;

    constructor(injector: Injector, renderer: Renderer2, modal: Modal) {
        super(injector);

        Object.assign(this, { renderer, modal });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<PopoverPageData>(this.rendering.fields);

        this.linkClass = this.showByodPromoStyles ? '' : this.pageData.buttonTheme === 'light' || [ 'light', 'light-grey' ].includes(this.theme) ? 'xm-btn-link-light' : 'xm-btn-link-dark';
        this.headerClass = this.showByodPromoStyles ? '' : this.pageData.headerClass || '';
        this.bodyContentClass = this.showByodPromoStyles ? '' : this.pageData.bodyContentClass || '';
        this.updateCopyrightYear();
    }

    public viewModal(): void {
        this.modal.descriptive(this.rendering.fields);
    }

    public mouseHoverEvent(event: Event): void {
        console.log(this.interval);
        //clearInterval(this.interval);
        if (event.type === MouseEventType.MOUSE_ENTER) {
            this.onMouseEnter();
        } else if (event.type === MouseEventType.MOUSE_LEAVE) {
            this.onMouseLeave();
        }
    }

    public generateImageFromCms(image: CmsMediaOptionsImage): MediaImageOptions {
        return Cloudinary.generateMediaOptionsFromCms(image);
    }

    private updateCopyrightYear(): void {
        this.modalData = this.rendering.fields;
        const currentYear: number = new Date().getFullYear();
        const key = 'length';
        if (this.modalData.descriptions[key] > 0) {
            const index = this.modalData.descriptions[key] - 1;
            const description = (<Field> this.modalData.descriptions[index].fields.paragraph).value;
            (<Field> this.modalData.descriptions[index].fields.paragraph).value = description ? description.toString().replace(/YEAR/g, currentYear.toString()) : '';
        }
    }

    private onMouseEnter(): void {
        const mouseEnterDelayTime: number = MarketingPopoverComponent.MOUSE_ENTER_DELAY_TIME;

        if (this.pageData.isOverlayPanel) {
            this.interval = setTimeout(() => {
                this.showOverlay = true;
            }, mouseEnterDelayTime);
        } else {
            this.showOverlay = false;
        }
    }

    private onMouseLeave(): void {
        const mouseLeaveDelayTime: number = MarketingPopoverComponent.MOUSE_LEAVE_DELAY_TIME;

        this.interval = setTimeout(() => {
            this.showOverlay = false;
        }, mouseLeaveDelayTime);
    }

}
