import { Component, ElementRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Cloudinary } from 'services/cloudinary';
import { WindowReference } from 'services/window';
import { ImageFit } from 'core/constants';
import { Util } from 'core/utils/util';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';
import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'xm-responsive-image',
    styleUrls: [ './responsive-image.scss' ],
    templateUrl: './responsive-image.html'
})
export class ResponsiveImageComponent extends SitecoreComponent implements OnDestroy, OnInit {
    private static SCALAR: number = 60;
    private static RETINA_SCALE: number = 2;
    @Input() public rendering: ComponentRendering;

    public imageUrl: string;
    public imageUrl2x: string;
    public isSVG: boolean;
    public imageCenter: string;
    public imageSize: string;
    public imageOptions: ResponsiveImageOptions;

    private currentBreakpoint: Breakpoint;
    private element: ElementRef;
    private subscriptions: Subscription[] = [];
    private triedSecondBounding: boolean = false;
    private windowReference: WindowReference;

    constructor(injector: Injector, element: ElementRef, windowReference: WindowReference) {
        super(injector);

        Object.assign(this, { element, windowReference });
    }

    public ngOnInit(): void {
        this.imageOptions = this.flattenFields<ResponsiveImageOptions>(this.rendering.fields);
        this.subscriptions.push(this.windowReference.breakpoint.subscribe((breakpoint: Breakpoint) => {
            this.currentBreakpoint = breakpoint;
            this.checkForBreakpoints();
        }));
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    private checkForBreakpoints(): void {
        let currentImage: SingletonResponsiveImage;

        switch (true) {
            case (this.currentBreakpoint.isSmall):
                currentImage = this.findSmall();
                break;
            case (this.currentBreakpoint.isMedium):
                currentImage = this.findMedium();
                break;
            case (this.currentBreakpoint.isLarge):
                currentImage = this.findLarge();
                break;
            default:
                currentImage = this.findXLarge();
        }

        if (!currentImage.url) {
            return;
        }

        this.isSVG = currentImage.url.endsWith('.svg');
        this.imageCenter = currentImage.position || 'center';
        this.imageSize = currentImage.fit || 'contain-fit';
        this.createImagePaths(currentImage);
    }

    private createImagePaths(currentImage: SingletonResponsiveImage): void {
        if (!this.isSVG && currentImage.useBoundingBox) {
            let width: number = 400;
            let height: number = 400;

            if (WindowReference.isWindowAvailable) {
                const box: ClientRect = this.element.nativeElement.getBoundingClientRect();
                width = this.roundUp(box.width);
                height = this.roundUp(box.height);
            }

            if (width > 0 || height > 0) {
                if (this.priorityHeight(currentImage.fit) && height > 0) {
                    this.imageUrl = Cloudinary.generateResponsiveUrl(-1, height, currentImage);
                    this.imageUrl2x = `${Cloudinary.generateResponsiveUrl(-1, height * ResponsiveImageComponent.RETINA_SCALE, currentImage)} 2x`;
                } else if (this.priorityWidth(currentImage.fit) && width > 0) {
                    this.imageUrl = Cloudinary.generateResponsiveUrl(width, -1, currentImage);
                    this.imageUrl2x = `${Cloudinary.generateResponsiveUrl(width * ResponsiveImageComponent.RETINA_SCALE, -1, currentImage)} 2x`;
                } else {
                    this.imageUrl = Cloudinary.generateResponsiveUrl(width, height, currentImage);
                    this.imageUrl2x = `${Cloudinary.generateResponsiveUrl(width * ResponsiveImageComponent.RETINA_SCALE, height * ResponsiveImageComponent.RETINA_SCALE, currentImage)} 2x`;
                }
            } else if (!this.triedSecondBounding) {
                this.triedSecondBounding = true;
                setTimeout(() => {
                    this.checkForBreakpoints();
                });
            } else {
                this.imageUrl = Cloudinary.generateResponsiveUrl(0, 0, currentImage);
            }

        } else {
            this.imageUrl = Cloudinary.generateResponsiveUrl(0, 0, currentImage);
            this.imageUrl2x = `${this.imageUrl} 2x`;
        }
    }

    private roundUp(value: number): number {
        return Math.ceil(value / ResponsiveImageComponent.SCALAR) * ResponsiveImageComponent.SCALAR;
    }

    private findXLarge(): SingletonResponsiveImage {
        if (this.imageOptions.xlargeUrl) {
            return {
                url: this.imageOptions.xlargeUrl,
                useBoundingBox: this.imageOptions.xlargeUseBoundingBox,
                trimTransparency: this.imageOptions.xlargeTrimTransparency,
                fit: this.imageOptions.xlargeImageFit,
                position: this.imageOptions.xlargeImagePosition
            };
        }

        return this.findLarge();
    }

    private findLarge(): SingletonResponsiveImage {
        if (this.imageOptions.largeUrl) {
            return {
                url: this.imageOptions.largeUrl,
                useBoundingBox: this.imageOptions.largeUseBoundingBox,
                trimTransparency: this.imageOptions.largeTrimTransparency,
                fit: this.imageOptions.largeImageFit,
                position: this.imageOptions.largeImagePosition
            };
        }

        return this.findMedium();
    }

    private findMedium(): SingletonResponsiveImage {
        if (this.imageOptions.mediumUrl) {
            return {
                url: this.imageOptions.mediumUrl,
                useBoundingBox: this.imageOptions.mediumUseBoundingBox,
                trimTransparency: this.imageOptions.mediumTrimTransparency,
                fit: this.imageOptions.mediumImageFit,
                position: this.imageOptions.mediumImagePosition
            };
        }

        return this.findSmall();
    }

    private findSmall(): SingletonResponsiveImage {
        return {
            url: this.imageOptions.smallUrl,
            useBoundingBox: this.imageOptions.smallUseBoundingBox,
            trimTransparency: this.imageOptions.smallTrimTransparency,
            fit: this.imageOptions.smallImageFit,
            position: this.imageOptions.smallImagePosition
        };
    }

    private priorityHeight(fit: string): boolean {
        return fit === ImageFit.HEIGHT;
    }

    private priorityWidth(fit: string): boolean {
        return fit === ImageFit.WIDTH;
    }
}
