import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ColorPickerComponent } from './color-picker/picker';
import { ProductCardComponent } from './card/card';
import { SharedModule } from 'components/shared/module';
import { ProductCarouselComponent } from './carousel';
import { ProductByodCardComponent } from './byod-card/byod-card';
//import { SwiperModule } from 'ngx-swiper-wrapper';

@NgModule({
    declarations: [
        ProductCardComponent,
        ProductByodCardComponent,
        ColorPickerComponent,
        ProductCarouselComponent
    ],
    exports: [
        ProductCardComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        SharedModule,
        //SwiperModule
    ]
})
export class ProductCarouselModule {}
