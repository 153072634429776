import { Component, Injector, Input, OnInit } from '@angular/core';

import { StorageToken } from 'core/constants';
import { SessionStorage } from 'services/storage/session';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'xm-shop-healthcheck',
    styleUrls: [ './healthcheck.scss' ],
    templateUrl: './healthcheck.html'
})
export class XmShopHealthCheckComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public cookieList: string[][] = [];
    public visitorSessionId: string;
    private sessionStorage: SessionStorage;

    constructor(sessionStorage: SessionStorage, injector: Injector) {
        super(injector);
        Object.assign(this, { sessionStorage });
    }

    public ngOnInit(): void {
        const cookieItems: string[] = document.cookie.split(';');
        cookieItems.forEach((cookie) => {
            const [ key, ...value ]: string[] = cookie.split('=');
            this.cookieList.push([ key, value.join('=') ]);
        });
        this.visitorSessionId = this.getVisitorSessionId();
    }

    public getVisitorSessionId(): string {
        return this.sessionStorage.get(StorageToken.VISITOR_SESSION_ID);
    }
}