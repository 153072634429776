import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'marketing-grid-text',
    styleUrls: [ './text.scss' ],
    templateUrl: './text.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarketingGridTextComponent extends SitecoreComponent {
    @Input() public rendering: ComponentRendering;

    constructor(injector: Injector) {
        super(injector);
    }
}
