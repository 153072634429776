import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ComponentRendering, Field } from '@sitecore-jss/sitecore-jss-angular';

import { SitecoreComponent } from 'components/shared/sitecore';

@Component({
    selector: 'xm-nav-profile',
    styleUrls: [ './profile.scss' ],
    templateUrl: './profile.html'
})
export class MarketingNavProfileComponent extends SitecoreComponent implements OnInit, OnChanges {
    @Input() public profileMessage: Field;
    @Input() public displayName: string;
    @Input() public rendering: ComponentRendering;
    @Output() public toggleMobileNav: EventEmitter<void> = new EventEmitter<void>();

    public displayNameFirstCharacter: string;
    public hasDisplayName: boolean;
    public pageData: NavMenuItem;
    public accountData: NavMenuItem;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.accountData = this.flattenFields<NavMenuItem>(this.rendering.fields);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.displayName) {
            this.displayNameFirstCharacter = this.displayName && this.displayName.slice(0, 1);
            this.hasDisplayName = Boolean(this.displayName);
        }
    }

    public closeMobileNav(): void {
        this.toggleMobileNav.emit();
    }
}
