import { Component, ElementRef, Injector, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { SitecoreComponent } from 'components/shared/sitecore';
import { MarketingUnlPricingModal } from 'components/unl-pricing-modal/unl-pricing-modal';
import { Modal } from 'core/services/modal';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'xm-unl-pricing-opener',
    templateUrl: 'unl-pricing-opener.html',
    styleUrls: [ 'unl-pricing-opener.scss' ]
})
export class MarketingUnlPricingOpener extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    @ViewChild('editModeView') public childTemplate: ElementRef;

    public pageData: UnlPricingModalData;
    public headerImage: MediaImageOptions;
    private modal: Modal;
    private dialog: MatDialog;

    constructor(injector: Injector, renderer: Renderer2, modal: Modal, dialog: MatDialog) {
        super(injector);

        Object.assign(this, { renderer, modal, dialog });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<UnlPricingModalData>(this.rendering.fields);
        if (this.pageData.headerImage) {
            this.headerImage = Cloudinary.generateMediaOptionsFromCms(this.pageData.headerImage);
        }
    }

    public viewModal(): void {
        this.modal.open<ComponentRendering>(this.dialog, MarketingUnlPricingModal, { width: '900px', data: this.rendering });
    }
}
