import { AfterViewInit, Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering, Field } from '@sitecore-jss/sitecore-jss-angular';
import { StateService } from '@uirouter/angular';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Util } from 'core/utils/util';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'marketing-data-options',
    styleUrls: [ './data-options.scss' ],
    templateUrl: './data-options.html'
})
export class MarketingDataOptionsComponent extends SitecoreComponent implements OnInit, AfterViewInit {
    @Input() public rendering: ComponentRendering;

    public pageData: DataOptionsPageData;
    public icon: MediaImageOptions;

    private state: StateService;

    constructor(injector: Injector, state: StateService) {
        super(injector);

        Object.assign(this, { state });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<DataOptionsPageData>(this.rendering.fields);

        this.rendering.fields.unlimitedPlanPriceOne = this.transformSuperscript(<Field<string>> this.rendering.fields.unlimitedPlanPriceOne);
        this.rendering.fields.unlimitedPlanPriceTwo = this.transformSuperscript(<Field<string>> this.rendering.fields.unlimitedPlanPriceTwo);
        this.rendering.fields.unlimitedPlanPriceThree = this.transformSuperscript(<Field<string>> this.rendering.fields.unlimitedPlanPriceThree);
        this.rendering.fields.unlimitedPlanPriceFour = this.transformSuperscript(<Field<string>> this.rendering.fields.unlimitedPlanPriceFour);

        if (this.pageData.icon) {
            this.icon = Cloudinary.generateMediaOptionsFromCms(this.pageData.icon);
        }
    }

    public ngAfterViewInit(): void {
        if (document.getElementById(this.state.params.data_option)) {
            document.getElementById(this.state.params.data_option).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
    }

    public generateImageFromCms(image: CmsMediaOptionsImage): MediaImageOptions {
        return Cloudinary.generateMediaOptionsFromCms(image);
    }

    private transformSuperscript(planOption: Field<string>): Field {
        if (planOption?.value) {
            planOption.value = Util.decorateFloatingNumbersWithSuperscript(planOption.value);
        }

        return planOption;
    }
}
