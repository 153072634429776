import { Component, Inject, Injector, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { SitecoreComponent } from 'components/shared/sitecore';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'xm-unl-pricing-modal',
    templateUrl: 'unl-pricing-modal.html',
    styleUrls: [ 'unl-pricing-modal.scss' ]
})
export class MarketingUnlPricingModal extends SitecoreComponent implements OnInit {
    @ViewChild(SwiperDirective) public swiper: SwiperDirective;

    public pageData: UnlPricingModalData;
    public rendering: ComponentRendering;
    public headerImage: MediaImageOptions;
    public leftArrowIcon: MediaImageOptions;
    public rightArrowIcon: MediaImageOptions;
    public carouselConfig: SwiperConfigInterface = {
        init: true,
        navigation: {
            prevEl: '.swiper-navigation-prev',
            nextEl: '.swiper-navigation-next'
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'bullets',
            bulletElement: 'button'
        }
    };

    private dialogRef: MatDialogRef<MarketingUnlPricingModal>;

    constructor(injector: Injector, renderer: Renderer2, dialogRef: MatDialogRef<MarketingUnlPricingModal>, @Inject(MAT_DIALOG_DATA) rendering: ComponentRendering) {
        super(injector);

        Object.assign(this, { renderer, dialogRef, rendering });
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<UnlPricingModalData>(this.rendering.fields);
        if (this.pageData.headerImage) {
            this.headerImage = Cloudinary.generateMediaOptionsFromCms(this.pageData.headerImage);
        }
        if (this.pageData.leftArrowIcon) {
            this.leftArrowIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.leftArrowIcon);
        }
        if (this.pageData.rightArrowIcon) {
            this.rightArrowIcon = Cloudinary.generateMediaOptionsFromCms(this.pageData.rightArrowIcon);
        }
    }

    public close(action: string): void {
        const response: ModalResponse = {
            close: action === 'close',
            primary: action === 'primary',
            secondary: action === 'secondary'
        };

        this.dialogRef.close(response);
    }

    public placeholderLoaded(): void {
        if (this.swiper) {
            this.swiper.update();
        }
    }
}
