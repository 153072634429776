import { Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'marketing-image-banner',
    styleUrls: [ './flexible-image-banner.scss' ],
    templateUrl: './flexible-image-banner.html'
})
export class MarketingFlexibleImageBannerComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public pageData: FlexibleImageBannerPageData;
    public textAlignment: string;
    public backgroundColorStyles: FlexibleImageBannerBackground = {};
    public breakpointImages: MediaImageOptions;
    public hasActions: boolean;

    public imageContainer: string;
    public sectionContainer: string;
    public textContainer: string;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<FlexibleImageBannerPageData>(this.rendering.fields);

        this.textAlignment = this.pageData.textAlign || 'align-left';
        this.textContainer = this.pageData.textContainer || 'text-container';

        if (this.pageData.foregroundImage) {
            this.breakpointImages = Cloudinary.generateMediaOptionsFromCms(this.pageData.foregroundImage);
        }

        if (this.pageData.backgroundColor) {
            this.backgroundColorStyles = {
                backgroundColor: this.pageData.backgroundColor
            };
        }

        this.imageContainer = this.pageData.imageContainer || '';
        this.sectionContainer = this.pageData.sectionContainer || '';

        this.hasActions = this.checkForActions();
    }

    private checkForActions(): boolean {
        return Boolean(this.hasFieldOrEditable(this.rendering.fields.buttonOne) ||
                this.hasFieldOrEditable(this.rendering.fields.buttonTwo) ||
                this.hasFieldOrEditable(this.rendering.fields.linkDecorationOne) ||
                this.hasFieldOrEditable(this.rendering.fields.linkDecorationTwo) ||
                this.rendering.fields.popover);
    }
}
