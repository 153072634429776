import { Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-angular';

import { SitecoreComponent } from 'components/shared/sitecore';
import { Cloudinary } from 'services/cloudinary';

@Component({
    selector: 'marketing-side-by-side-banner',
    styleUrls: [ './side-by-side.scss' ],
    templateUrl: './side-by-side.html'
})
export class MarketingSideBySideComponent extends SitecoreComponent implements OnInit {
    @Input() public rendering: ComponentRendering;

    public pageData: SideBySideBannerPageData;
    public leftImages: MediaImageOptions;
    public rightImages: MediaImageOptions;

    constructor(injector: Injector) {
        super(injector);
    }

    public ngOnInit(): void {
        this.pageData = this.flattenFields<SideBySideBannerPageData>(this.rendering.fields);

        if (this.pageData.leftImage) {
            this.leftImages = Cloudinary.generateMediaOptionsFromCms(this.pageData.leftImage);
        }

        if (this.pageData.rightImage) {
            this.rightImages = Cloudinary.generateMediaOptionsFromCms(this.pageData.rightImage);
        }
    }
}
